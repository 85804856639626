@import "./../variables";

.main-body {
    transition-duration:  .35s;
    margin-left: 257px;
    main {
        position: relative;
        z-index: 0;
        padding: 1rem!important;
        @media screen and (min-width: $sm) {
            padding: 1.5rem!important;
        }
    }
}
.hidden-menu{
     margin-left: 69px;
}
