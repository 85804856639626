nav {
    @apply flex fixed top-0 left-0 w-full h-[77px] justify-end;
    border-bottom: 1px;
    border-color: #e9ecef;
    border-style: solid;
    // background: #8080800d;
    .profile-img{
        @apply items-center justify-center h-[77px] w-[80px]
        p-[1rem] mr-[.5rem] flex;
        img{
            border-radius: 50%;
            @apply h-full w-full ;
            object-fit: cover;
        }
    }
}
