@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,600&display=swap");

@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Kanit", sans-serif !important;
    font-weight: 300;
}

.MuiInput-input,
.MuiSelect-select,
.MuiMenuItem-root {
    font-weight: 300 !important;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth !important;
}
body {
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #8080800d;
    .App {
        overflow: hidden;
    }
}
