@import "../../variables.scss";

#login-page {
    display: flex;
    @apply h-full w-full top-0 right-0 bottom-0 left-0 bg-white;
    position: absolute;
    #form-blog {
        @apply flex flex-col w-full sm:w-[50%] justify-center items-center;
        .title {
            @apply flex flex-col items-center;
            h1 {
                font-size: 33px;
                font-weight: 500;
            }
            p {
                font-size: 16px;
                color: #8a92a6;
                font-weight: 300;
            }
        }
        .form {
            @apply mt-[16px] p-[1rem] lg:min-w-[436px] flex flex-col min-w-full;
            .group {
                @apply mt-[0px] flex flex-col;
                label {
                    font-size: 16px;
                    color: #8a92a6;
                    font-weight: 300;
                }
                input {
                    @apply p-[8px] text-[16px] mt-[8px] w-full;
                    border: 1px solid #3a57e8;
                    border-radius: 4px;
                }
            }
            .group-check-forgot {
                @apply mt-[16px] flex justify-between;
                .check-box {
                    @apply flex gap-x-[.33rem] items-center;
                    input[type="checkbox"] {
                        width: 16px;
                        height: 16px;
                        border: 1px solid #8a92a6;
                        color: red;
                    }
                    p {
                        font-size: 16px;
                        color: #8a92a6;
                        font-weight: 300;
                    }
                }
                .forgot {
                    a {
                        color: #3A57E8;
                        font-weight: 400;
                    }
                }
            }
            .btn {
                @apply mt-[1.75rem] w-full flex justify-center;
                button {
                    @apply bg-[#3A57E8] text-[16px] font-[500] text-white;
                    padding: 8px 60px 8px 60px;
                    border-radius: 4px;
                }
            }
            .col-sign-up {
                @apply mt-[16px] flex flex-row justify-center;
                p {
                    font-size: 16px;
                    a {
                        font-size: 16px;
                        color: #3A57E8;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    #image-blog {
        @apply hidden w-full sm:w-[50%] sm:block;
        background: url(../../../public/images/bglogin.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: white;
    }
}
