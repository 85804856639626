.app-info-page {
  margin-top: 77px;
  .title {
    @apply text-[33px] mb-6;
  }
  .content {
    // @apply grid gap-3 grid-cols-3;
    @apply flex gap-3 flex-wrap justify-start;
  }
  .custom-card {
    min-width: 30%;
    .preview {
      @apply justify-center h-[150px] flex;
      img {
        @apply object-scale-down;
      }
    }

    .btn-about {
      @apply flex justify-end mt-2;
    }
    .btn {
      @apply flex justify-between m-1;
      .custom-upload {
        cursor: pointer;
        @apply flex bg-slate-500 w-[60%] p-2 text-white justify-center items-center;
        border-radius: 10px;
      }
      input[type="file"]::file-selector-button {
        display: none;
      }
    }
  }
}
