.sidebar-menu {
    @apply w-[257px] h-full bg-white overflow-y-auto fixed;
    box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
    transition: all 0.3s;

    .col-logo {
        @apply flex items-center p-[1rem] text-[#0048b3] font-[400] text-[30px] h-[77px] border justify-center;
        border-bottom: 2px solid #e9ecef;

        h1 {
            padding-left: 4px;
        }

        img {
            width: 38px;
        }
    }

    .menu-lang {
        @apply flex flex-col w-full items-center mt-2 text-[18px] text-[#8A92A6] font-[600] justify-center;

        .lang {
            @apply w-full justify-around mt-1 flex flex-wrap;
        }
    }

    .title-menu {
        @apply p-[1rem] justify-between flex text-[20px] text-[#8A92A6] font-[400] w-full;

        .hidden-side {
            cursor: pointer;
        }
    }

    ul.menu-list {
        @apply pl-[1rem] pr-[1rem] gap-[8px] flex flex-col;
        transition: all 0.5s;

        li {
            cursor: pointer;
        }

        a {
            @apply text-[18px] text-[#8A92A6] font-[600] w-full flex items-center gap-2 justify-start;
            padding: 8px 24px;

            svg {
                width: 18px;
                height: 18px;
            }
        }

        ul.menu-group {
            @apply pl-[1.5rem] h-full overflow-hidden flex-col;
            display: none;
            animation-name: fadeInDown;
            -webkit-animation-name: fadeInDown;
            animation-fill-mode: both;
            animation-duration: 0.35s;
            -webkit-animation-fill-mode: both;
        }

        @keyframes fadeInDown {
            from {
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }
            to {
                transform: translate3d(0, 20px, 0);
                opacity: 0;
            }
        }

        @keyframes fadeInUp {
            from {
                transform: translate3d(0, -20px, 0);
            }
            to {
                transform: translate3d(0, 0px, 0);
                opacity: 1;
            }
        }

        ul.sub-active {
            display: block;
            animation-name: fadeInUp;
            -webkit-animation-name: fadeInUp;
            animation-duration: 0.2s;
            animation-fill-mode: both;
            -webkit-animation-duration: 0.2s;
            -webkit-animation-fill-mode: both;
        }

        .active {
            @apply bg-[#3A57E8] rounded-[4px] text-white;
            box-shadow: 0px 2px 4px rgba(138, 146, 166, 0.3);
        }

        .side-buttom {
            @apply bottom-[1rem] absolute w-full flex flex-col;

            .logout {
                @apply text-[red] font-[600] text-[16px] w-full justify-start;
            }

            .v-mobile {
                @apply text-[#8A92A6] font-[400] text-[14px] mt-2;
            }
        }
    }
}

.hidden {
    @apply fixed w-[69px] h-full bg-white overflow-y-auto;
    box-shadow: 0px 10px 30px rgba(17, 38, 146, 0.05);
    transition: all 0.3s;

    .col-logo {
        border-bottom: 2px solid transparent;

        h1 {
            display: none;
        }
    }

    .title-menu {
        p {
            display: none;
        }

        @apply p-[1rem] justify-center flex text-[20px] text-[#8A92A6] font-[400] w-full;

        .hidden-side {
            cursor: pointer;
        }
    }

    ul.menu-list {
        @apply pl-[.2rem] pr-[.2rem] gap-[8px] flex flex-col;
        transition: all 0.5s;

        li {
            cursor: pointer;
        }

        a {
            @apply text-[18px] text-[#8A92A6] font-[600] w-full flex items-center justify-center gap-2;

            p {
                display: none;
            }

            svg {
                width: 24px;
                height: 24px;
            }
        }

        ul.menu-group {
            padding-left: 0.1rem;
        }
    }
}
