#version-page{
    .title {
        font-size: 33px;
    }
    .col-tabtop{
        display: flex;
        margin-bottom: 8px;
        justify-content: end;
    }
}
.swal2-container {
    z-index: 2000 !important;
}
.custom-modal {
    z-index: 1;
    .body-modal {
        @apply p-[2rem] bg-white absolute top-[50%] left-[50%] flex flex-col;
        @apply flex-wrap;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        button {
            margin-top: 1rem;
            background-color: #6ee37adc;
            color: rgb(83, 83, 41);
        }
        .modal-top {
            h1 {
                font-size: 24px;
                font-weight: 500;
                color: #0048b3;
            }
        }
        width: 70%;
        .MuiFormControl-root {
            @apply mt-2;
        }
        .manage-posts {
         @apply mb-2;
            .content {
                @apply flex flex-row gap-4 justify-between;
            }
        }
        .radio-luck{
            @apply mt-2 flex items-center ;

        }

        .modal-footer {
            @apply mt-4;
            @apply flex justify-end;
            .btn-cancel {
                @apply mr-2 bg-slate-400 text-white;
            }
            .btn-save {
                @apply bg-[#0048b3] text-white;
            }
        }
    }
}
